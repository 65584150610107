import { LitElement, css, html } from 'lit';
import iconPath from 'bootstrap-icons/bootstrap-icons.svg';
import { customElement, property } from 'lit/decorators.js';

// manually typed because
// a) bootstrap doesn't provide a type for its icons
// b) inventory all icons we use today

export const iconNames = [
    '123',
    'arrow-counterclockwise',
    'arrow-down-right',
    'arrow-down-short',
    'arrow-left-circle',
    'arrow-left-right',
    'arrow-right',
    'arrow-right-short',
    'arrow-up-circle',
    'arrow-up-right',
    'arrow-up-short',
    'bar-chart-line-fill',
    'bell',
    'calendar-date',
    'card-heading',
    'card-text',
    'cash-coin',
    'chat-dots',
    'check',
    'check-circle',
    'check-circle-fill',
    'check-square-fill',
    'check2-circle',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'clock',
    'cloud-arrow-up-fill',
    'clouds',
    'columns-gap',
    'dash',
    'download',
    'exclamation-circle',
    'exclamation-circle-fill',
    'exclamation-triangle',
    'file-earmark-arrow-down',
    'file-earmark-binary',
    'file-earmark-code',
    'file-earmark-excel',
    'file-earmark-image',
    'file-earmark-minus',
    'file-earmark-pdf',
    'file-earmark-plus',
    'file-earmark-slides',
    'file-earmark-text',
    'file-earmark-word',
    'filter-left',
    'folder-plus',
    'folder-x',
    'folder2-open',
    'graph-up',
    'hash',
    'house-door',
    'info-circle',
    'info-circle-fill',
    'journal',
    'journal-arrow-up',
    'journal-plus',
    'journal-x',
    'pause-fill',
    'pencil',
    'pencil-square',
    'people',
    'person',
    'person-check',
    'play-fill',
    'plus-circle-fill',
    'power',
    'question-circle',
    'reply',
    'save',
    'search',
    'send',
    'send-check',
    'sort-down',
    'sort-up',
    'square',
    'trash',
    'wrench',
    'x',
    'x-octagon',
    'plus',
] as const;

type PliIconName = (typeof iconNames)[number];
export interface PliIconProps {
    name: PliIconName;
    size: number;
}

@customElement('pli-icon')
class PliIcon extends LitElement implements PliIconProps {
    @property()
    name: PliIconName = 'search';

    @property({ type: Number })
    size: number = 16;

    static styles = [
        css`
            :host {
                --size: var(--size-1);
                display: inline-flex;
                width: var(--size);
                height: var(--size);
                flex-shrink: 0;
            }
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
        `,
    ];

    render() {
        this.style.setProperty('--size', this.size ? `${this.size}px` : 'var(--size-1)');
        const iconUrl = `${iconPath}#${this.name}`;

        return html`
            <svg class="bi" fill="currentColor">
                <use href="${iconUrl}" />
            </svg>
        `;
    }
}
