import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {
    PliRoundedElementProps,
    PliRoundedElementRadius,
    createRoundedElementClassnames,
} from './utils/create-rounded-element-classnames';
import { PliInputController } from './controllers/input-controller';
import { styles } from './styles';
import { PliInputState } from './types/input-state';

export const availableInputSizes = ['sm', 'md'] as const;
export const availableInputTypes = ['text', 'date', 'number'] as const;
type PliInputSize = (typeof availableInputSizes)[number];
type PliInputType = (typeof availableInputTypes)[number];

export type PliInputProps = PliRoundedElementProps<{
    placeholder?: string;
    state?: PliInputState;
    size?: PliInputSize;
    value: string;
    type?: PliInputType;
    maxlength?: number;
}>;

@customElement('pli-input')
class PliInput extends LitElement implements PliInputProps {
    @property()
    placeholder = 'Placeholder';

    @property()
    value = '';

    @property()
    maxlength = undefined;

    @property()
    state?: PliInputState = 'initial';

    @property()
    radiusTopRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomRight?: PliRoundedElementRadius = 'md';

    @property()
    radiusBottomLeft?: PliRoundedElementRadius = 'md';

    @property()
    radiusTopLeft?: PliRoundedElementRadius = 'md';

    @property()
    size?: PliInputSize = 'md';

    @property()
    type?: PliInputType = 'text';

    controller: PliInputController = new PliInputController(this);

    static styles = [
        styles.base,
        styles.rounded,
        css`
            :host {
                display: block;
                width: 100%;
                --border: var(--border-default);
                --focus-box-shadow: var(--input-border-focus-shadow);
                --padding-md: var(--size-1) var(--size-0-75);
                --padding-sm: var(--size-0-5) var(--size-1);
            }
            input {
                width: inherit;
                background-color: var(--bs-body-bg, var(--body-bg));
                align-self: stretch;
                border: var(--border);
                padding: var(--padding);
                font-size: var(--font-size-body);
                transition: box-shadow 150ms ease-in-out;
            }

            input:focus {
                box-shadow: var(--focus-box-shadow);
                outline: 0;
            }
        `,
    ];

    _createStyleFromState() {
        this.style.setProperty('--border', `var(--border-${this.state})`);
        this.style.setProperty('--focus-box-shadow', `var(--input-border-focus-shadow-${this.state})`);
    }

    render() {
        this._createStyleFromState();
        this.style.setProperty('--padding', `var(--padding-${this.size})`);
        const { radiusBottomLeft, radiusBottomRight, radiusTopLeft, radiusTopRight, type } = this;
        const { classNames } = createRoundedElementClassnames({
            radiusBottomLeft,
            radiusBottomRight,
            radiusTopLeft,
            radiusTopRight,
        });
        return html` <input
            class="${classNames}"
            type="${type}"
            placeholder="${this.placeholder}"
            @input="${this.controller.inputHandler}"
            value="${this.value}"
            maxlength="${this.maxlength}"
        />`;
    }
}
